/* Address List, Vue Component */
<template>
  <v-card id="address-list">
    <v-card-title>Addresses</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'address-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Address</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>

        <v-text-field
          v-model="search.FullName"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Full Name"
          class="mr-4"
        ></v-text-field>



        <v-select
          v-model="filter.IsDeleted"
          :items="filterItems.IsDeleted"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter IsDeleted"
          class="mr-4"
        ></v-select>



        <MemberSelect class="mr-4" v-model="filter.MemberId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />



        <CountrySelect class="mr-4" v-model="filter.CountryId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.FullName`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'address-view', params: { id: item.Id } }">
      {{ item.FullName }}
    </router-link>

    </template>

		<template #[`item.IsDeleted`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.IsDeleted).variant">{{ booleanIcons(item.IsDeleted).icon }}</v-icon>
    </template>




      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown :item="item" :actionOptions="actionOptions" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import MemberSelect from '@/views/member/MemberSelect'
import CountrySelect from '@/views/country/CountrySelect'

export default {
  components: {
    ActionsDropdown,
    MemberSelect,
		CountrySelect
  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Full Name', value: 'FullName' },
		{ text: 'Line 1', value: 'Address1' },
		{ text: 'Line 2', value: 'Address2' },
		{ text: 'Line 3', value: 'Address3' },
		{ text: 'Line 4', value: 'Address4' },
		{ text: 'Postcode', value: 'Postcode' },
		{ text: 'Telephone', value: 'Telephone' },
		{ text: 'IsDeleted', value: 'IsDeleted' },
		{ text: 'Member Reference', value: 'MemberId_Reference' },
		{ text: 'Member Email', value: 'MemberId_Email' },
		{ text: 'Country Name', value: 'CountryId_Name' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Addresses || []);
  const totalItems = computed(() => store.state.app.AddressesTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({
    IsDeleted: [{"text":"Deleted","value":true},{"text":"Active","value":false}]
  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchAddresses', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }


    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'address-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'address-edit' }
    ]

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
