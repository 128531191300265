<template>
    <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            small
            icon
            v-bind="attrs"
            v-on="on"
            >
            <v-icon size="18">
                {{ icons.mdiDotsVertical }}
            </v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item
            v-for="(option, i) in shownActionOptions"
            :key="i"
            @click="Click(item, option)"
            >
            <v-list-item-title>
                <v-icon
                size="18"
                class="me-2"
                >
                {{ option.icon }}
                </v-icon>
                <span>{{ option.title }}</span>
            </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {
  mdiDotsVertical
} from '@mdi/js'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        actionOptions: {
            type: Array,
            required: true
        }
    },
    computed: {
        shownActionOptions() {
            return this.actionOptions.filter((option) => {
                return (!option.show || option.show(this.item));
            });
        }
    },
    data: function() {
        return {
            icons: {
                mdiDotsVertical
            }
        }
    },
    methods: {
        Click(item, option) {
            if (option.target) {
                this.$router.push({ name: option.target, params: { id: item.Id } });
            } else if (option.click) {
                option.click(item, option);
            }
        }
    }
}
</script>