/* Member Select, Vue Component */
<template>
  <v-select :value="value"
            @input="onChange"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :solo-inverted="inverted"
            :solo="!inverted"
            :outlined="outlined"
            :dense="dense"
            :hide-details="hideDetails"
            clearable
            flat
            item-value="Id"
            :label="label"
            :placeholder="placeholder"
            :readOnly="readOnly"
            :disabled="disabled"
            :error-messages="errorMessages"
            class="lookup"
            :rules="rules">
    <template v-slot:selection="data">
      {{ data.item.Reference }}
    </template>
    <template v-slot:item="data">
      <v-list-item-content class="py-1">
        <v-list-item-title v-html="data.item.Summary"></v-list-item-title>

        <v-list-item-subtitle v-if="!dense" v-html="data.item.Email"></v-list-item-subtitle>

      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
  import { requiredLookup } from '@/datahelpers/validation'

  export default {
    props: {
      value: {
        required: true
      },
      label: {
        type: String
      },
      placeholder: {
        type: String,
        default: "Select Member"
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      "errorMessages": {
        type: Array,
        default: function () {
          return [];
        }
      },
      rules: {
        type: Array,
        default: function () {
          return [];
        }
      },
      inverted: {
        type: Boolean,
        default: true
      },
      dense: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        isLoading: true,
        search: null,
        validators: {
          requiredLookup
        }
      };
    },
    computed: {
      items() {
        return this.$store.state.app.Members;
      }
    },
    methods: {
      onChange(item) {
        this.$emit("input", item);
      },
    },
    beforeMount: function () {
      this.$store
        .dispatch('app/fetchMembers', {
        })
        .then(response => {
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
</script>

<style lang="scss" scoped>
  .v-select-list::v-deep .v-list-item {
    height: auto;
  }
</style>
